import React from 'react';
import { UserOutlined,PhoneOutlined,SafetyCertificateOutlined,HomeOutlined } from '@ant-design/icons';
import { Input,Button,message } from 'antd';
import "./NewsLetter.css"

function NewsLetter() {
     const [messageApi, contextHolder] = message.useMessage();
     async function fetchDati (tipo){     
        let dati={cognome:"",nome:"",telefono:"",città:"",password:"",tipo:tipo};     
        if(tipo==="iscrizione"){
        
        dati.cognome=document.getElementsByName("cognome")[0].value;
        dati.nome=document.getElementsByName("nome")[0].value;
        dati.telefono=document.getElementsByName("telefono")[0].value;
        dati.città=document.getElementsByName("città")[0].value;
        dati.password=document.getElementsByName("password")[0].value;

        }else
            {
                dati.telefono=document.getElementsByName("telefonoDel")[0].value;
                dati.password=document.getElementsByName("passwordDel")[0].value;
            }

        try {
            const config = {
                method: 'POST',
                body: JSON.stringify(dati),
                //mode: 'no-cors'
            }
            const response = await fetch("https://cazzatocamicie.altervista.org/API/data.php", config)
            //const json = await response.json()
            // eslint-disable-next-line default-case

            switch(response.status) {
                case 201:
                    messageApi.open({
                        type: 'success',
                        content: `Grazie per l'iscrizione alla nostra newsLetter!`,
                        style: {
                          marginTop: '30vh',
                        },
                      });
                      document.getElementsByName("cognome")[0].value="";
                      document.getElementsByName("nome")[0].value="";
                      document.getElementsByName("telefono")[0].value="";
                      document.getElementsByName("città")[0].value="";
                      document.getElementsByName("password")[0].value="";
                    return response.status;
                   
                case 401:
                    messageApi.open({
                        type: 'error',
                        content: `Attenzione numero di telefono già inserito!`,
                        style: {
                          marginTop: '30vh',
                        },
                      });
                    document.getElementsByName("telefono")[0].focus();
                break
                case 202:
                    messageApi.open({
                        type: 'success',
                        content: `I tuoi dati sono stati eliminati con successo!`,
                        style: {
                          marginTop: '30vh',
                        },
                      });
                      document.getElementsByName("telefonoDel")[0].value="";
                      document.getElementsByName("passwordDel")[0].value="";
                break
                case 402:
                    messageApi.open({
                        type: 'error',
                        content: `Utente non trovato o password errata!`,
                        style: {
                          marginTop: '30vh',
                        },
                      });
                break
                default: console.log("default");

            }

        } catch (error) {
             console.log(error);   //
        }
    }


    function inviaReg() {
        if(document.getElementsByName("cognome")[0].value!==""&&
        document.getElementsByName("nome")[0].value!==""&&
        document.getElementsByName("telefono")[0].value!==""&&
        document.getElementsByName("città")[0].value!==""&&
        document.getElementsByName("password")[0].value!==""
        ){
        fetchDati("iscrizione");

        }
      else{
        messageApi.open({
          type: 'warning',
          content: 'Dovresti compilare tutti i campi!',
          style: {
            marginTop: '30vh',
          },
        });}
    }
    function cancellaI() {
        if(
        document.getElementsByName("telefonoDel")[0].value!==""&&
        document.getElementsByName("passwordDel")[0].value!==""
        ){
        fetchDati("cancella");

        }
      else{
        messageApi.open({
          type: 'warning',
          content: 'Dovresti compilare tutti i campi!',
          style: {
            marginTop: '30vh',
          },
        });}
    }
    return (
         <>
  

         {contextHolder}
         <div id='container'>
         <div id='para'>
         <p>
         Vuoi essere sempre aggiornato sulle ultime novità e offerte del nostro negozio ? 
         Iscriviti alla nostra newsletter e riceverai subito un buono sconto del 10% da utilizzare
          sul tuo primo acquisto! Non perdere questa occasione, inserisci il tuo numero di telefono e 
          clicca su "Iscriviti ora"!

         </p>
         
         </div>
        <div id='register'>
            <Input name='cognome' size="large" placeholder="Cognome" prefix={<UserOutlined />} />
            <br />
            <br />
            <Input name='nome' size="large" placeholder="Nome" prefix={<UserOutlined />} />
            <br />
            <br />
            <Input name='telefono' size="large" placeholder="Telefono" prefix={<PhoneOutlined />} />
            <br />
            <br />
            <Input name='città' size="large" placeholder="Città" prefix={<HomeOutlined />} />
            <br />
            <br />
            <Input name='password' size="large" placeholder="Password" prefix={<SafetyCertificateOutlined />} />
            <br />
            <br />
            <Button name='BtnReg'type="primary" onClick={inviaReg}>Iscriviti ora</Button>

        </div>

        <div id='cancella'>
            <Input name='telefonoDel' size="large" placeholder="Telefono" prefix={<PhoneOutlined />} />
            <br />
            <br />
            <Input name='passwordDel' size="large" placeholder="Password" prefix={<SafetyCertificateOutlined />} />
            <br />
            <br />
            <Button name='BtnDel'type="primary" onClick={cancellaI}>Cancella Iscrizione</Button>

        </div>
        </div>

        </>
    )
}

export default NewsLetter