import React from 'react'
import { Card, Button, Space } from 'antd';
import "./cardTessuto.css";
const { Meta } = Card;


const CardTessuto = ({ texScelto, setTexScelto, objtex, clic,scegli }) => {    //=({ codice, prezzo, materiale }) => {
  const { codice, prezzo, materiale } = objtex;
  let visibile = false;

  if (document.querySelector(".Zoom")) {
    
    if (document.querySelector(".Zoom").parentElement.id.slice(1) === codice) {
      visibile = true;
    }
  }

  return (
    <Card
      //ref={ref} 
      // onMouseDown={togliVecchio}
      onClick={clic}
      hoverable
      // style={{ width: 150 }}
      className={"noZoom"}
      cover={<img alt="example" src={`https://cazzatocamicie.altervista.org/immagini/tessuti/${codice}.JPG`} />}
    >
      <Meta title={`${codice}   -     ${prezzo},00 €`} description={materiale} />

      {visibile && <BtnCard scegli={scegli}codice={codice}prezzo={prezzo}/>}
      
    </Card>
  )
};

const BtnCard = ({scegli,codice,prezzo}) => {
  return (
    <>
      <Space.Compact align="end">
        <Button type="primary" onMouseDown={()=>scegli(codice,prezzo)}>Seleziona</Button>
       
      </Space.Compact>
    </>
  )
}
export default CardTessuto;