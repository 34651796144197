import React, { useState } from 'react'
import { Card, Divider, Button } from 'antd';
import "./carrello.css";
import { Navigate, Link } from 'react-router-dom';
const { Meta } = Card;
function carrello({ Setbb, bad, attiva }) {
  return (
    <div>{localStorage.getItem("wish")  ? <CarrelloAttivo Setbb={Setbb} bad={bad} /> : <CarrelloVuoto attiva={attiva} />}</div>
  )
}
const CarrelloAttivo = (props) => {
  let texScelti = JSON.parse(localStorage.getItem("wish"));
  let ordine=[];
  if(texScelti){
  texScelti.forEach(el => {//correggere bug
    ordine.push(el.codice)
    ordine.push(`https://cazzatocamicie.altervista.org/appTest/tessuti/${el.codice}.JPG`)

  });
}
  
  let totale = 0;

  const rimuovi = (e) => {
    const elDaElim = e.currentTarget.parentElement.innerText.slice(0, 5);
    let nuovoArray = texScelti.filter(el => el.codice !== elDaElim);
    localStorage.setItem("wish", JSON.stringify(nuovoArray))
    props.Setbb(props.bad - 1)
  }
  return (
    <>

      <Divider plain id="dividerTop">Tessuti nel Carrello</Divider>
      <div className='carrel'>
        {//texScelti?
          texScelti.map((item, i) => {
            totale = totale + Number(texScelti[i].prezzo);
            return (
              <Card
                key={texScelti[i].codice + "a"}
                hoverable
                style={{
                  width: 200,
                }}
                cover={<img alt="example" src={`https://cazzatocamicie.altervista.org/immagini/tessuti/${texScelti[i].codice}.JPG`} />}
              >
                <Meta title={`${texScelti[i].codice}`} description={`Prezzo ${texScelti[i].prezzo},00`} />
                <Button onClick={rimuovi} className='bottone'>Rimuovi</Button>
              </Card>
            )
          })//:null
        }
      </div>
      <Divider className='dividBottom' />
      <h4>{`Totale ${totale},00 €`}</h4>
      <Button type="primary"
        onClick={()=>{localStorage.removeItem("wish");props.Setbb(0)}}
        href={`https://wa.me/393205705559?text=Vorrei una camicia con questi tessuti :%0a\n ${ordine.join(`%0a`)}%0aTotale ${totale}€`}>
        Completa ordine
      </Button>
      <Button className='btnTorna'><Link to="/Tessuti">Torna ai tessuti</Link></Button>
    </>
  )
}
const CarrelloVuoto = ({ attiva }) => {
  const [vuoto, setVuoto] = useState(true)
  setTimeout(() => {
    attiva(false);
    setVuoto(false)
  }, 2500);

  return (


    (vuoto === true) ? <h1>Carrello Vuoto</h1> : <Navigate to="../Tessuti" />


  )
}

export default carrello



// import { Divider, Card, Button } from "antd";
// import "./carrello.css";
// export default function Carrello(props) {
//   let texScelti = props.car;
//   function togli(event) {
//     props.Setbb(props.bad - 1);
//     //props.attiva(false)
//     if (props.bad === 1) {
//       props.attiva(false);
//     }
//     texScelti.texS.splice(event.target.id, 1);
//     texScelti.texSLink.splice(event.target.id, 1);
//     props.setCar(texScelti);
//     //console.log("cccc", props.car.texSLink.join("\r\n"));
//   }

//   return (
//     <>
//       <h3>Carrello</h3>
//       <Divider>Questi sono i tuoi tessuti!</Divider>

//       <div className="carrel">
//         {props.car.texS.map((tex, index) => (
//           <Card
//             key={index}
//             hoverable
//             style={{
//               width: 240,
//             }}
//             cover={<img alt={tex} src={props.car.texSLink[index]} />}
//           >
//             <button id={index} onClick={togli}>
//               Rimuovi
//             </button>
//           </Card>
//         ))}
//       </div>

//       <Divider />
//       <Button
//         href={`https://wa.me/393205705559?text=Vorrei una camicia con questi tessuti :\n ${props.car.texSLink.join(
//           `%0a`
//         )}`}
//       >
//         Invia
//       </Button>
//     </>
//   );
// }
