import React, { useState, useEffect } from "react";
import { Button} from "antd";
import CardTessuto from "./CardTessuto";
import './Tessuti.css';

export default function Tessuti({Setbb,bad,attiva}) {
  const [texScelto, setTexscelto] = useState("vuoto");
  const [texCliccato, setCliccato] = useState("vuoto");
  const [tessuti, setTessuti] = useState([]);
  const [totalTex, setTotalTex] = useState([]);
  //const [wish,setWish]=useState(localStorage.getItem("wish")?JSON.parse(localStorage.getItem("wish")):[])
 let wish=localStorage.getItem("wish")?JSON.parse(localStorage.getItem("wish")):[];
  
 //generare key univoche
//  const generateKey = (pre) => {
//   return `${ pre }_${ new Date().getTime() }`;
// }
  const scegli=(codic,prezzo)=>{//quandosi sceglie un tessuto
    let esiste="no";
    let i=0;
    wish=localStorage.getItem("wish")?JSON.parse(localStorage.getItem("wish")):[]
   while (esiste==="no"&&i < wish.length)
    {
      if(wish[i].codice===codic){esiste="si"}
      
      i++;
    };

    if(esiste==="si"){alert("Già presente nel carrello ")}
    else{
    wish.push({"codice":codic,"prezzo":prezzo});
    Setbb(bad+1)
    attiva("true")
    localStorage.setItem("wish",JSON.stringify(wish))
  }
  }

  function mostra(e) {
    let categoria = "";
    if (e.target.textContent === "Tutto") {
      categoria = "Tutto";

    } else if (e.target.textContent === "Rigati") {
      categoria = "Rigato";

    } else if (e.target.textContent === "Quadrettati") {
      categoria = "Quadrettato";

    } else if (e.target.textContent === "Moda") {
      categoria = "Fantasia";

    } else { categoria = "Unita"; }
    
    const filtro = totalTex.filter(function (item) {
      if (item.trama === categoria) {
        return item;
      }else return null

    })
    if (categoria === "Tutto") {
      setTessuti(totalTex)
    } else {
      setTessuti(filtro)
    }


  }

const richiamaTex = async () => {
    const response = await fetch(
      "https://cazzatocamicie.altervista.org/API/apiLeggereTex.php"
    );
    const texTot = await response.json();
    setTessuti(texTot);
    setTotalTex(texTot);
  };
  useEffect(() => {
    richiamaTex();
  }, []);

  const clic = (e) => {
    if (texCliccato === "vuoto") {
      //mostra il primo tex
      setCliccato(e.currentTarget.parentElement.id);
      e.currentTarget.classList.toggle("noZoom")
      e.currentTarget.classList.toggle("Zoom")
    } else if (e.currentTarget.parentElement.id === texCliccato) { 
      //chiude stesso tex
      setCliccato("vuoto")
      e.currentTarget.classList.toggle("noZoom")
      e.currentTarget.classList.toggle("Zoom")
    } else {
      //mostra nuovo tex e chiude quello vecchio
      setCliccato(e.currentTarget.parentElement.id)
      document.querySelector(`#${texCliccato}`).firstChild.classList.toggle("noZoom");
      document.querySelector(`#${texCliccato}`).firstChild.classList.toggle("Zoom");
      e.currentTarget.classList.toggle("noZoom")
      e.currentTarget.classList.toggle("Zoom")
    }
  }


  return (
    <>
      <div id="menu2" >
        <Button type="text" onClick={(e) => { mostra(e) }}>
          Tutto
        </Button>
        <Button type="text" onClick={(e) => { mostra(e) }}>Tinta Unita</Button>
        <Button type="text" onClick={(e) => { mostra(e) }}>Rigati</Button>
        <Button type="text" onClick={(e) => { mostra(e) }}>Quadrettati</Button>
        <Button type="text" onClick={(e) => { mostra(e) }}>Moda</Button>
      </div>
      
      <div id="containerCard">
        <ul >
          {
            tessuti.map((el, i) => {
              if (tessuti[i].disponibile==="si"){
              return (
                <li key={ tessuti[i].codice} id={`c${tessuti[i].codice}`}>
                  {/* <CardTessuto{...tessuti[i]}/>     ------    key={ generateKey(tessuti[i].codice) }*/}
                  <CardTessuto
                   objtex={tessuti[i]} 
                   texScelto={texScelto} 
                   setTexScelto={setTexscelto} 
                   clic={clic} 
                   scegli={scegli}
                  //  Setbb={Setbb}
                  //  bad={bad}
                    />
                </li>
              )}else return null
            })
          }
        </ul>
      </div>
    </>
  );

}





