import React from 'react'
import "./Contatti.css"
import { Button, Card, message, Input, Tooltip,Divider } from 'antd';
import { InfoCircleOutlined, UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';




let email={nome:"",mail:"",messaggio:""};
const { TextArea } = Input;

const inviaMail= async()=>{
    email.nome=document.getElementsByName("Nome")[0].value;
    email.mail=document.getElementsByName("Mail")[0].value;
    email.messaggio=document.getElementsByName("Msg")[0].value;
    
    try {
        const config = {
            method: 'POST',
            body: JSON.stringify(email)
        }
        const response = await fetch("https://cazzatocamicie.altervista.org/API/apiMail.php", config)
        //const json = await response.json()
        if (response.ok) {
            //return json
            console.log("mail inviata");
            
            document.getElementsByName("Nome")[0].value="";
            document.getElementsByName("Mail")[0].value="";
            document.getElementsByName("Msg")[0].value="";
            return response
        } else {
            //
            console.log("mail NON inviata. c'è un problema");
        }
    } catch (error) {
         console.log(error);   //
    }
    

}

function Contatti() {
    const [messageApi, contextHolder] = message.useMessage();
    const info = () => {
        if(document.getElementsByName("Nome")[0].value!==""&&
        document.getElementsByName("Mail")[0].value!==""&&
        document.getElementsByName("Msg")[0].value!==""
        ){
      messageApi.open({
        type: 'success',
        content: 'Grazie per averci contattato!',
        style: {
          marginTop: '30vh',
        },
      });}
    };


    return (
        <>
                {contextHolder}
            <h1>Contatti</h1>
            <Divider></Divider> 
            <div id='parent'>
                <div id='child1'> 
                    <p id="para">
                        Il nostro punto vendita è a Racale(LE),
                        ma possiamo spedire in tutta Europa.
                        Compila il form per inviarci una mail, oppure
                        se ti è più comodo usa WhatsApp(in basso a destra).
                        Risponderemo con piacere a qualsiasi dubbio!
                    </p>
                    <div id='contForm'>
                        <form id='formio'>
                   
                            <Input
                                name='Nome'
                                placeholder="Nome"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                suffix={
                                    <Tooltip title="Scrivi il tuo nome!">
                                        <InfoCircleOutlined
                                            style={{
                                                color: 'rgba(0,0,0,.45)',
                                            }}
                                        />
                                    </Tooltip>
                                }
                            />
                            <br />
                            <Input
                                name='Mail'
                                placeholder="Mail"
                                prefix={<MailOutlined className="site-form-item-icon" />}
                                suffix={
                                    <Tooltip title="Scrivi la tua mail!">
                                        <InfoCircleOutlined
                                            style={{
                                                color: 'rgba(0,0,0,.45)',
                                            }}
                                        />
                                    </Tooltip>
                                }
                            />
                            <br />

                            <TextArea
                                name='Msg'
                                showCount
                                maxLength={100}
                                style={{
                                    height: 120,
                                    resize: 'none',
                                }}
                                
                                placeholder="Il tuo messaggio!"
                            />
                            <Button name='BtnMail'type="primary"onMouseDown={inviaMail}onClick={info}>Invia</Button>
                        </form>
                    </div>
                </div>
                <div id='child2'>

                    <div id='orari'>


                        <Card
                            size="small"
                            title="Orari Negozio"

                            style={{
                                width: 300,
                                background: "#d32f2f",
                                color: "whitesmoke"
                            }}
                        >
                            <p>Lunedì &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 9:30-12:30 &nbsp;&nbsp;  17:00-20:30</p>
                            <p>Martedì &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 9:30-12:30 &nbsp;&nbsp;  17:00-20:30</p>
                            <p>Mercoledì &nbsp;&nbsp;&nbsp;&nbsp; 9:30-12:30 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Chiuso</p>
                            <p>Giovedì &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 9:30-12:30 &nbsp;&nbsp;  17:00-20:30</p>
                            <p>Venerdì &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 9:30-12:30 &nbsp;&nbsp;  17:00-20:30</p>
                            <p>Sabato &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 9:30-12:30 &nbsp;&nbsp;  17:00-20:30</p>
                            <p>Domenica &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Chiuso&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chiuso</p>

                        </Card>


                    </div>
                    <div id='chiama'>
                        <PhoneOutlined style={{ marginRight: "10px" }} />
                        <a href="tel:3205705490">Chiamaci  &nbsp; - &nbsp;  3205705490</a>
                        <p><strong>Indirizzo:</strong></p>
                        <p>Via Gallipoli, 300</p>
                        <p>73055 Racale (LE)</p>
                    </div>
                    <div id='mappa'>
                        <iframe title="mappadigoogle" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.6095967879432!2d18.085212675230284!3d39.97248048255944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134402ff5b69058b%3A0x5f3e52da89a9748b!2sCazzato%20Camicie!5e0!3m2!1sit!2sit!4v1696327945729!5m2!1sit!2sit" style={{ border: "5", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}></iframe>
                    </div>

                </div>
            </div>



        </>
    )
}

export default Contatti


////////////////////////giù messaggio mail inviata


// const openMessage = () => {
//     messageApi.open({
//       key,
//       type: 'loading',
//       content: 'Loading...',
//     });
//     setTimeout(() => {
//       messageApi.open({
//         key,
//         type: 'success',
//         content: 'Loaded!',
//         duration: 2,
//       });
//     }, 1000);
//   };
//   return (
//     <>
//       {contextHolder}
//       <Button type="primary" onClick={openMessage}>
//         Open the message box
//       </Button>
//     </>
//   );
