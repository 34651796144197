import React, { useEffect, useState } from 'react';
import { Card, Divider, Carousel, Button, Space } from "antd"
import { Link } from 'react-router-dom';
import "./Home2.css"
export default function Home2() {
    const [texCliccato, setCliccato] = useState("vuoto");
    const [modelli, setModelli] = useState([])
    const richiamaMod = async () => {
        const response = await fetch(
            "https://cazzatocamicie.altervista.org/API/apiLeggereModelli.php"
        );
        const model = await response.json();
       
        setModelli(model.listaModelli)
        
    };
    useEffect(() => {
        richiamaMod();
    }, []);
   

    const cliccami = (e) => {
        if (texCliccato === "vuoto") {
          //mostra il primo tex
          setCliccato(e.currentTarget.parentElement.id);
          e.currentTarget.classList.toggle("noZoomM")
          e.currentTarget.classList.toggle("ZoomM")
        } else if (e.currentTarget.parentElement.id === texCliccato) {
          //chiude stesso tex
          setCliccato("vuoto")
          e.currentTarget.classList.toggle("noZoomM")
          e.currentTarget.classList.toggle("ZoomM")
        } else {
          //mostra nuovo tex e chiude quello vecchio
          setCliccato(e.currentTarget.parentElement.id)
          document.querySelector(`#${texCliccato}`).firstChild.classList.toggle("noZoomM");
          document.querySelector(`#${texCliccato}`).firstChild.classList.toggle("ZoomM");
          e.currentTarget.classList.toggle("noZoomM")
          e.currentTarget.classList.toggle("ZoomM")
        }
      }



    return (
        <div>
            <div id='container2' className='immaginis2'>
                <Carousel autoplay effect="fade">
                    <div>
                        <img className='immaginis' key="111" src='https://cazzatocamicie.altervista.org/immagini/1.jpg' alt="non ce" />

                    </div>
                    <div>
                        <img className='immaginis' key="112" src='https://cazzatocamicie.altervista.org/immagini/2.jpg' alt="non ce" />
                    </div>
                    <div>
                        <img className='immaginis' key="113" src='https://cazzatocamicie.altervista.org/immagini/3.jpg' alt="non ce" />
                    </div>
                    <div>
                        <img className='immaginis' key="114" src='https://cazzatocamicie.altervista.org/immagini/4.jpg' alt="non ce" />
                    </div>
                </Carousel>
                <h1 id='claim'>Cazzato Camicie</h1>
                <p id='sottoClaim'>La camicia che non fa una piega!</p>
            </div>
            <Space wrap id='social'>
                <Button type="primary" className='btnTorna'><Link to="/Tessuti">Tessuti</Link></Button>
                <Button href='https://www.facebook.com/people/Cazzato-Camicie/100041491586181/' target="_blank">Facebook</Button>
                <Button href='https://www.instagram.com/cazzatocamicie/' target="_blank">Instagram</Button>

            </Space>

            <Divider>I nostri modelli</Divider>

            {/* inizio modelli */}
            <div id="containerModelli">
                <ul >
                    {
                        modelli.map((el, i) => {
                            return (
                                <li key={modelli[i]} id={`modello${i}`}>
                                    <Card
                                        onClick={cliccami}
                                        hoverable
                                         //style={{ width: 150,height:250 }}
                                        className={"noZoomM"}
                                        cover={<img alt="example" src={`https://cazzatocamicie.altervista.org/immagini/modelli/${modelli[i]}`} />}
                                    >


                                    </Card>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            {/* fine modelli */}



        </div>

    )
};