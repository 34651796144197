// import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import ResponsiveAppBar2 from './component/navabar2';
import Home2 from './component/Home2.js';
import What from './component/whatsapp';
import {BrowserRouter as Router,  Route,Routes,Navigate} from "react-router-dom"
import ChiSiamo from './component/chiSiamo';
import Tessuti from './component/Tessuti';
import Carrello from './component/Carrello';
import Contatti from './component/Contatti';
import NewsLetter from './component/NewsLetter';  
function App() {
  const [badgeC, setBadge] = useState(localStorage.getItem("wish")?JSON.parse(localStorage.getItem("wish")).length:0);
  //const [car, setCar] = useState({texS:[],texSLink:[]});
  const [carrelloattivo, attiva] = useState(localStorage.getItem("wish")?true:false);
  
    return (
    <Router>
    <div className="App">
      {/*  <Tit/>
         <ResponsiveAppBar/> */}
        <ResponsiveAppBar2 bad={badgeC} carrelloattivo={carrelloattivo}/>
        <Routes>
        <Route path="/" element={<Home2/>}/>
        <Route path="Carrello" element={<Carrello  Setbb={setBadge} bad={badgeC} attiva={attiva}/>}/> {/*car.texS.length < 1 ? <Navigate to="../Prodotti" /> :*/}
        <Route path="Chi siamo" element={<ChiSiamo/>}/>
        <Route path="Tessuti" element={<Tessuti Setbb={setBadge} bad={badgeC} attiva={attiva}/>}/>
        <Route path="Contatti" element={<Contatti/>}/>
        <Route path="NewsLetter" element={<NewsLetter/>}/>
        <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <What/>
    </div>
    </Router>
  );
}

export default App;
