
import React,{useState,useRef,useEffect} from "react";
import { Card as Cacca } from 'antd';


const { Meta } = Cacca;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
   }

export default function ChiSiamo(){
    const [stato, contrai] = useState({contratto:"si",parag:` La nostra è un'azienda a conduzione familiare e..`});
    const precedente = usePrevious(stato);
    let testo=(` La nostra è un'azienda a conduzione familiare e per questo possiamo 
    contare sulla passione che ci contraddistingue dal momento in cui scegliamo 
    attentamente i nostri tessuti fino a quando consegnamo la camicia realizzata 
    con la massima accuratezza da parte nostra e dopo una serie di controlli per 
    curare anche il più piccolo dettaglio. I nostri obbiettivi sono soddisfare la 
    nostra clientela dando pari importanza tanto all'aspetto estetico quanto al 
    confort che può dare una nostra camicia. La puntualità poi è un'altro aspetto 
    nostra politica aziendale che ci preme osservare anche per instaurare un rapporto 
    di fiducia con i nostri clienti che sanno di poter contare sulle capacità della
    nostra azienda. In parole povere : IL CLIENTE PRIMA DI TUTTO!
    Aspetto Artigianale Dal taglio alla preparazione delle parti intelate, 
    le asole e tutto ciò che riguarda la confezione della camicia, ogni cosa 
    viene eseguita all'interno della nostra azienda per garantire un livello di 
    qualità che non dipende da manodopera a basso costo ed essere certi cosi' di 
    consegnare un prodotto veramente Made In Italy e non soltanto impacchettato in
    Italia dopo una produzione estera.`);
    function espandi(){
      
    if (stato.contratto==="si") {
        contrai({contratto:"no",parag:testo})
    } else {
        contrai({contratto:precedente.contratto,parag:precedente.parag})
         }

    };
    const sil={
        
    height: "30vh",
    objectFit:"cover",
    }

    ////////////secondo testo inizio///////////////
    const [stato2, contrai2] = useState({contratto:"si",parag:` Capi su misura realizzati artigianalmente. Qui trov...`});
    const precedente2 = usePrevious(stato2);
    let testo2=(` Capi su misura realizzati artigianalmente. Qui troverai camicie che soddisferanno tutte 
    le tue esigenze e ti garantiranno eleganza in qualsiasi occasione senza privarti della comodità che può
     dare solo un capo realizzato su misura. I tuoi dati e le tue misure verranno salvati presso la nostra 
     banca dati cosi' dopo il primo ordine non dovrai fare altro che scegliere il tessuto e sbizzarrirti 
     avendo come freno solo la tua fantasia. Avrai la possibilità di personalizzare la tua camicia con 
     l'abbinamento di vari tessuti, la scelta dei bottoni, il modello del collo, dei polsi e le iniziali 
     testimoni che la camicia e' unica ed è stata confezionata proprio per te! E per le personalizzazioni 
     più azzardate la linea diretta con il nostro staff di modellisti e sarti! Non perdere tempo a cercare 
     una camicia che ti andrà bene di collo ma sarà troppo larga, o avrà le maniche troppo lunghe. Inizia a 
     sfogliare i nostri tessuti e a creare la tua camicia secondo le tue necessità oppure vieni a trovarci 
     presso il nostro punto vendita dove avrai maggiore assistenza e una più vasta gamma di tessuti e accessori.`);
    function espandi2(){
      
    if (stato2.contratto==="si") {
        contrai2({contratto:"no",parag:testo2})
    } else {
        contrai2({contratto:precedente2.contratto,parag:precedente2.parag})
         }

    };
    ////////////secondo testo fine///////////////
    return(
    <>  
        
   
        {/* ///////////////////////////////////////////// */}
        
        <Cacca
            onClick={espandi}
            hoverable
            style={{
            width: "90%",
            marginTop:"5vh",
            marginLeft:"auto",
            marginRight:"auto",
            textAlign: "justify",
            letteSpacing: "3px",
            }}
            cover={<img style={sil} alt="example" src="https://cazzatocamicie.altervista.org/immagini/3.jpg" />}
        >
            <Meta title="Chi siamo..." description={stato.parag} />
        </Cacca>
        
        {/* ///////////////////////////////////////////////////// */}
           {/* ////////////////secondo testo inizio///////////////////////////// */}
        
           <Cacca
            onClick={espandi2}
            hoverable
            style={{
            width: "90%",
            marginTop:"5vh",
            marginLeft:"auto",
            marginRight:"auto",
            textAlign: "justify",
            letteSpacing: "3px",
            }}
            cover={<img style={sil} alt="example" src="https://cazzatocamicie.altervista.org/immagini/4.jpg" />}
        >
            <Meta title="Vuoi saperne di più?" description={stato2.parag} />
        </Cacca>
        
        {/* /////////////////////secondo testo fine//////////////////////////////// */}
        
      
    </>
    )
}