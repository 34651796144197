import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { Badge } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
///palette inizio
import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import "./navbar.css";

const theme = createTheme({
  palette: {
    primary: {
      main: red[700],
    },

  },
});
///palette fine

const settings = ["Profile", "Account", "Dashboard", "Logout"];
let carrelloTip=''
function ResponsiveAppBar2(props) {
  if (props.bad<1) {carrelloTip='Carrello vuoto'
    
  } else {
    carrelloTip='Carrello'
  }
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky" theme={theme}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Link to="/" className="stilink">
            {" "}
            <Typography
              variant="h6"
              noWrap
              // component="a"
              // to ="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "white",
                textDecoration: "none",
              }}
            >
              Cazzato Camicie
            </Typography>{" "}
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem id="ho" onClick={handleCloseNavMenu}>
                <Link to="/" className="stilink">
                  <Typography
                    sx={{ color: "darkslategrey" }}
                    textAlign="center"
                  >
                    Home
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem id="chi" onClick={handleCloseNavMenu}>
                <Link to="/Chi siamo" className="stilink">
                  <Typography
                    sx={{ color: "darkslategrey" }}
                    textAlign="center"
                  >
                    Chi siamo
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem id="con" onClick={handleCloseNavMenu}>
                <Link to="/Tessuti" className="stilink">
                  <Typography
                    sx={{ color: "darkslategrey" }}
                    textAlign="center"
                  >
                    Tessuti
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem id="cont" onClick={handleCloseNavMenu}>
                <Link to="/Contatti" className="stilink">
                  <Typography
                    sx={{ color: "darkslategrey" }}
                    textAlign="center"
                  >
                    Contatti
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem id="cont" onClick={handleCloseNavMenu}>
                <Link to="/NewsLetter" className="stilink">
                  <Typography
                    sx={{ color: "darkslategrey" }}
                    textAlign="center"
                  >
                    Newsletter
                  </Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            // component="a"
            // to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Cazzato Camicie
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Link to="/Chi siamo" className="stilink">
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  letterSpacing: ".2rem",
                  display: "block",
                }}
              >
                Chi siamo
              </Button>
            </Link>
            <Link to="/Tessuti" className="stilink">
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  letterSpacing: ".2rem",
                  display: "block",
                }}
              >
                Tessuti 
              </Button>
            </Link>
            <Link to="/Contatti" className="stilink">
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  letterSpacing: ".2rem",
                  display: "block",
                }}
              >
                Contatti
              </Button>
            </Link>
            <Link to="/NewsLetter" className="stilink">
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  letterSpacing: ".2rem",
                  display: "block",
                }}
              >
                Newsletter
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={carrelloTip}>
              <Badge count={props.bad}>
                {/* <IconButton  sx={{ p: 0 }} > */}
                <Link
                  to="/Carrello"
                  onClick={
                    props.carrelloattivo ? null : (e) => e.preventDefault()
                  }
                >
                  <ShoppingCartOutlined
                    style={{ fontSize: "180%", color: "white" }}
                  />
                </Link>
                {/* </IconButton> */}
              </Badge>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar2;
